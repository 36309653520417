<!--漏洞概览-->
<template>
  <div>
    <!-- Header Background Image -->
    <!-- Sign Up Image And Headings -->
    <div class="sign-up-header" style="background-image: url('images/folib/banner.jpg')">
      <div class="content">
        <h1 class="mb-5">FOLIB 漏洞库</h1>
        <p class="text-lg">本漏洞库专为国产制品库folib以及开源治理服务foeyes提供数据服务.</p>
      </div>
    </div>

    <!-- / Header Background Image -->

    <!-- User Profile Card -->
    <a-card :bordered="false" class="card-profile-head">
      <template #title>
        <a-row type="flex" align="middle" style="padding: 0 16px;">
          <a-col :span="24" :md="16" class="col-info">
            <a-avatar :size="74" shape="square"  style="border-radius: 8px; background-image: linear-gradient( 310deg, #141727, #3a416f ); margin-right: 10px"
                      :src="vulnerability.baseSeverity!==null?'images/folib/'+vulnerability.baseSeverity.toLocaleLowerCase()+'.svg':'images/folib/fovuln.svg'" />
            <div class="avatar-info">
              <h4 class="font-semibold m-0">{{ vulnId }} </h4>  <a-tag color="blue">NVD</a-tag> <a-tag v-if="vulnerability.cnId!==null" color="purple">CNNVD</a-tag>
              <p>{{vulnerability.vulnName.substr(vulnerability.vulnName.length-100,vulnerability.vulnName.length)}}</p>
            </div>
          </a-col>
          <a-col :span="24" :md="8" style="display: flex; align-items: center; justify-content: flex-end">


            <div class="table-avatar-info">
              <a-avatar v-if="vulnerability.baseSeverity === 'HIGH'" shape="circle" :size="24" src="images/folib/high.svg" />
              <a-avatar v-if="vulnerability.baseSeverity === 'MEDIUM'" shape="circle" :size="24" src="images/folib/medium.svg" />
              <a-avatar v-if="vulnerability.baseSeverity === 'CRITICAL'" shape="circle" :size="24" src="images/folib/critical.svg" />
              <a-avatar v-if="vulnerability.baseSeverity === 'LOW'" shape="circle" :size="24" src="images/folib/low.svg" />
              <a-avatar v-if="!vulnerability.baseSeverity" shape="circle" :size="24">N</a-avatar>
              <div class="avatar-info">
                <!--                <p  v-if="!vulnerability.baseSeverity" class="mb-0 text-dark">N/A</p>-->
                <p v-if="vulnerability.baseSeverity" class="mb-0 text-dark">{{ vulnerability.baseSeverity}}</p>
              </div>
              <a-divider type="vertical" />
              <div class="table-avatar-info">
                <a-tag v-if="vulnerability.baseScore>=9.0"  color="purple">{{ vulnerability.baseScore }}</a-tag>
                <a-tag v-if="vulnerability.baseScore<9.0&&vulnerability.baseScore>=7.0"  color="red">{{ vulnerability.baseScore }}</a-tag>
                <a-tag v-if="vulnerability.baseScore<7.0&&vulnerability.baseScore>=4.0"  color="orange">{{ vulnerability.baseScore }}</a-tag>
                <a-tag v-if="vulnerability.baseScore<4.0&&vulnerability.baseScore>0"  color="blue">{{ vulnerability.baseScore }}</a-tag>
                <a-tag v-if="!vulnerability.baseScore">N/A</a-tag>
              </div>
            </div>
<!--            <p style="margin-left: 36px;">-->
<!--              <a @click="handleMore">更多></a>-->
<!--            </p>-->
          </a-col>
        </a-row>
      </template>
    </a-card>

    <div :bordered="false" class="header-solid mb-24 card-profile-list">
      <a-row :gutter="16">
        <a-col :span="16">
          <a-card :bordered="false" class="mb-24 ant-card-box-shadow">
            <template #title>
              <h6 class="font-semibold m-0">概览</h6>
            </template>
            <p class="text-dark">
              {{vulnerability.vulnName}}
            </p>
            <hr class="gradient-line"/>
            <a-descriptions title="以下是关于本漏洞的描述信息" :column="1">
              <a-descriptions-item label="CVE编号">
                {{vulnId}}
              </a-descriptions-item>
              <a-descriptions-item v-if="vulnerability.cnId" label="CNNVD编号">
                {{vulnerability.cnId}}
              </a-descriptions-item>
              <a-descriptions-item label="创建时间">
                {{formatTimestamp(vulnerability.createDate)}}
              </a-descriptions-item>
              <a-descriptions-item label="发布时间">
                {{vulnerability.publishedDate}}
              </a-descriptions-item>
              <a-descriptions-item label="更新时间">
                {{vulnerability.lastModifiedDate}}
              </a-descriptions-item>
              <a-descriptions-item label="CVE描述">
                {{descriptionData[0].value}}
              </a-descriptions-item>
<!--              <a-descriptions-item v-if="vulnerability.cnId" label="描述">-->
<!--                {{descriptionData[0].value}}-->
<!--              </a-descriptions-item>-->

              <a-descriptions-item v-if="vulnerability.cnId" label="中文描述" >
                {{descriptionData[0].zhValue}}
              </a-descriptions-item>

            </a-descriptions>
          </a-card>

          <a-card :bordered="false" class="mb-24 ant-card-box-shadow">
            <template #title>
              <h6 class="font-semibold m-0">CVSS影响分布</h6>
            </template>

            <!--CVSS得分-->
            <a-row class="text-center">
              <a-col class="mb-sm-2 mb-0" :xs="{ span: 5, offset: 1 }" :lg="{ span: 6, offset: 1 }">
                <div>
                  <h6 class="chart-title">CVSS基本得分</h6>
                  <vue-easy-pie-chart :bar-color="severityColor((cvssBaseScore / 10) * 100)"
                                      :track-color="recessedColor"
                                      :scale-color="severityColor((cvssBaseScore / 10) * 100)"
                                      line-cap="butt"
                                      :line-width="5"
                                      :scale-length="5"
                                      :percent="cvssBaseScore * 10"
                                      :size="90"
                                      :animate="true">{{ cvssBaseScore.toFixed(1) }}
                  </vue-easy-pie-chart>
                </div>
              </a-col>
              <a-col class="mb-sm-2 mb-0 d-sm-down-none" :xs="{ span: 5, offset: 1 }" :lg="{ span: 6, offset: 1 }">
                <div>
                  <h6 class="chart-title">CVSS影响性得分</h6>
                  <vue-easy-pie-chart :bar-color="primaryColor"
                                      :track-color="recessedColor"
                                      :scale-color="primaryColor"
                                      line-cap="butt"
                                      :line-width="5"
                                      :scale-length="5"
                                      :percent="cvssImpactScore * 10"
                                      :size="90"
                                      :animate="true"
                  >{{ cvssImpactScore.toFixed(1) }}</vue-easy-pie-chart>
                </div>
              </a-col>
              <a-col class="mb-sm-2 mb-0 d-sm-down-none" :xs="{ span: 5, offset: 1 }" :lg="{ span: 6, offset: 1 }">
                <div>
                  <h6 class="chart-title">CVSS可利用性得分</h6>
                  <vue-easy-pie-chart :bar-color="primaryColor"
                                      :track-color="recessedColor"
                                      :scale-color="primaryColor"
                                      line-cap="butt"
                                      :line-width="5"
                                      :scale-length="5"
                                      :percent="cvssExploitScore * 10"
                                      :size="90"
                                      :animate="true"
                  >{{ cvssExploitScore.toFixed(1) }}</vue-easy-pie-chart>
                </div>
              </a-col>
            </a-row>
            <hr class="gradient-line"/>
            <!--OWASP得分-->
            <a-row class="text-center" style="margin-top: 16px;">
              <a-col class="mb-sm-2 mb-0" :xs="{ span: 5, offset: 1 }" :lg="{ span: 6, offset: 1 }">
                <div>
                  <h6 class="chart-title">OWASP可能性得分</h6>
                  <vue-easy-pie-chart :bar-color="severityColor((owaspRRLikelihoodScore / 10) * 100)"
                                      :track-color="recessedColor"
                                      :scale-color="primaryColor"
                                      line-cap="butt"
                                      :line-width="5"
                                      :scale-length="5"
                                      :percent="owaspRRLikelihoodScore * 10"
                                      :size="90"
                                      :animate="true">{{ owaspRRLikelihoodScore.toFixed(1) }}
                  </vue-easy-pie-chart>
                </div>
              </a-col>
              <a-col class="mb-sm-2 mb-0 d-sm-down-none" :xs="{ span: 5, offset: 1 }" :lg="{ span: 6, offset: 1 }">
                <div>
                  <h6 class="chart-title">OWASP技术影响得分</h6>
                  <vue-easy-pie-chart :bar-color="primaryColor"
                                      :track-color="recessedColor"
                                      :scale-color="primaryColor"
                                      line-cap="butt"
                                      :line-width="5"
                                      :scale-length="5"
                                      :percent="owaspRRTechnicalImpactScore * 10"
                                      :size="90"
                                      :animate="true"
                  >{{ owaspRRTechnicalImpactScore.toFixed(1) }}</vue-easy-pie-chart>
                </div>
              </a-col>
              <a-col class="mb-sm-2 mb-0 d-sm-down-none" :xs="{ span: 5, offset: 1 }" :lg="{ span: 6, offset: 1 }">
                <div>
                  <h6 class="chart-title">OWASP业务影响得分</h6>
                  <vue-easy-pie-chart :bar-color="primaryColor"
                                      :track-color="recessedColor"
                                      :scale-color="primaryColor"
                                      line-cap="butt"
                                      :line-width="5"
                                      :scale-length="5"
                                      :percent="owaspRRTechnicalImpactScore * 10"
                                      :size="90"
                                      :animate="true"
                  >{{ owaspRRTechnicalImpactScore.toFixed(1) }}</vue-easy-pie-chart>
                </div>
              </a-col>
            </a-row>
          </a-card>

          <a-card :bordered="false" class="mb-24 ant-card-box-shadow">
            <template #title>
              <h6 class="font-semibold m-0">CWE影响分析</h6>
              <hr class="gradient-line"/>
            </template>
            <a-row :gutter="12">
              <a-col :span="24" :md="24" :lg="12"  v-for="cwe in vulnerability.cwes" :key="cwe.cweId">
                <div class="desc-item">
                  <p class="overview-item">
                    <span>CWEID</span>
                    <span><a :href="cweLink(cwe)">{{`CWE-${cwe.cweId}`}} <a-icon type="link" /> </a></span>
                  </p>
                  <p class="overview-item">
                    <span>名称</span>
                    <span>{{ cwe.name}}</span>
                  </p>
                  <p class="overview-item">
                    <span>中文名</span>
                    <span>{{cwe.zhName}}</span>
                  </p></div>
              </a-col>
            </a-row>
          </a-card>
          <a-card :bordered="false" class="mb-24 ant-card-box-shadow">
            <template #title>
              <h6 class="font-semibold m-0">参考资料</h6>
              <hr class="gradient-line"/>
            </template>

            <a-list
                class="categories-list"
                item-layout="horizontal"
                :split="false"
                :data-source="referenceData"
            >
              <a-list-item slot="renderItem" slot-scope="item">
                <a-button :href="item.url" slot="actions" type="link">
                  <a-icon type="right" class="text-dark" />
                </a-button>
                <a-list-item-meta
                    :title="item.name"
                >
                  <span slot="description" class="text-sm" v-html="item.url"></span>
                  <a-avatar
                      slot="avatar"
                      :size="32"
                      style="border-radius: 12px;"
                      shape="square"
                      class="bg-gradient-dark">
                    <a-icon type="compass" :theme="'filled'" twoToneColor="#141414" />
                  </a-avatar>
                </a-list-item-meta>
              </a-list-item>
              <hr class="gradient-line my-10">
            </a-list>


          </a-card>
          <div v-if="credits.length">
            <a-card :bordered="false" class="mb-24 ant-card-box-shadow" title="鸣谢">
              <div v-for="(item, i) in credits" :key="i">
                <p>
                  <a :href="item.url">{{item.name}}</a>
                </p>
              </div>
            </a-card>
          </div>
        </a-col>
        <a-col :span="8">
          <a-card :bordered="false" class="mb-24 ant-card-box-shadow">
            <template #title>
              <h6 v-if="vulnerability.cvssVersion==='2.0'" class="font-semibold m-0">CVSS:2.0/{{vectorString}}</h6>
              <h6 v-if="vulnerability.cvssVersion==='3.1'"  class="font-semibold m-0">{{vectorString}}</h6>
              <hr class="gradient-line"/>
            </template>
            <ChartRadar :vectorString="vectorString" :cvssVersion="vulnerability.cvssVersion"/>
          </a-card>

          <a-card :bordered="false" class="mb-24 ant-card-box-shadow">
            <template #title>
              <h6 class="font-semibold m-0">影响软件包列表</h6>
              <hr class="gradient-line"/>
            </template>
            <a-list
                class="categories-list"
                item-layout="horizontal"
                :split="false"
                :data-source="vulnerableData"
            >
              <a-list-item slot="renderItem" slot-scope="item">


                  <a-list-item-meta
                      :title="item.cpe23Uri"
                  >
                    <span v-if="item.versionStartIncluding||item.versionEndExcluding" slot="description" class="text-sm" v-html="'开始版本:'+item.versionStartIncluding+'|修复版本:'+item.versionEndExcluding"></span>
                    <a-avatar
                        slot="avatar"
                        :size="32"
                        style="border-radius: 12px;"
                        shape="square"
                        class="bg-gradient-dark">
                      <a-icon type="bug" :theme="'filled'" twoToneColor="#141414" />
                    </a-avatar>
                  </a-list-item-meta>
                  <a-button  slot="actions" type="link">
                    <a-icon type="right" class="text-dark" />
                  </a-button>
                  <hr class="gradient-line my-10">

              </a-list-item>

            </a-list>
          </a-card>
        </a-col>
      </a-row>
    </div>
<!--    <vuln-detail ref="vulnerability" :vulnerability="vulnerability" />-->
  </div>
</template>

<script>
import { getVulnerabilityDetail } from "@/api/vulnerabilities.js"
import {valueWithDefault, formatTimestamp, extractVulnerable} from "@/utils/util"
import VulnerabilitiesOverView from "./VulnerabilitiesOverView.vue"
import ChartRadar from './ChartRadar.vue'
import VulnDetail from './VulnDetail.vue'
import VueEasyPieChart from 'vue-easy-pie-chart'
export default {
  components: { VulnerabilitiesOverView, ChartRadar, VulnDetail, VueEasyPieChart },

  data() {
    return {
      tabActive: 1,
      vulnerability: {},
      vulnId: null,
      vectorString: '',
      primaryColor: '#20a8d8',
      recessedColor: '#eeeeee',
      criticalColor: '#f86c6b',
      highColor: '#fd8c00',
      mediumColor: '#ffc107',
      lowColor: '#4dbd74',
      vulnerableData:[],
    }
  },
  computed: {
    credits() {
      const credits = this.vulnerability.credits ? JSON.parse(this.vulnerability.credits) :[]
      return credits
    },
    referenceData() {
      const referenceData = this.vulnerability.referenceData ? JSON.parse(this.vulnerability.referenceData) :[]
      return referenceData
    },
    descriptionData() {
      const descriptionData = this.vulnerability.descriptionData ? JSON.parse(this.vulnerability.descriptionData) : []
      return descriptionData
    },
    cvssBaseScore() {
      return valueWithDefault(
          this.vulnerability.baseScore,
          0
      )
    },
    cvssImpactScore() {
      return valueWithDefault(
          this.vulnerability.impactScore,
          0
      )
    },
    cvssExploitScore() {
      return valueWithDefault(
          this.vulnerability.exploitabilityScore,
          0
      )
    },
    owaspRRLikelihoodScore() {
      return valueWithDefault(this.vulnerability.owaspRRLikelihoodScore, 0)
    },
    owaspRRTechnicalImpactScore() {
      return valueWithDefault(this.vulnerability.owaspRRTechnicalImpactScore, 0)
    },
    owaspRRBusinessImpactScore() {
      return valueWithDefault(this.vulnerability.owaspRRBusinessImpactScore, 0)
    },
    cweLink () {
      return (cwe = {}) => {
        return `https://cwe.mitre.org/data/definitions/${cwe.cweId}`
      }
    },
    cweLabel () {
      return (cwe = {}) => {
        return `CWE-${cwe.cweId}: ${cwe.name}`
      }
    },
    sourceLabel() {
      return (source = '') => {
        switch (source.toUpperCase()) {
          case "NVD":
            return "National Vulnerability Database";
          case "NPM":
            return "NPM Advisories";
          case "OSSINDEX":
            return "Sonatype OSS Index";
          case "VULNDB":
            return "VulnDB (Risk Based Security)";
          case "SNYK":
            return "Snyk";
          default:
            return "";
        }
      }
    },
  },
  created() {
    this.initialize()
  },
  methods: {
    formatTimestamp,
    // severity scoreChart-color
    severityColor(score) {
      if (!score) {
        return this.primaryColor;
      } else if (score >= 9.0) {
        return this.criticalColor;
      } else if (score >= 7.0) {
        return this.highColor;
      } else if (score >= 4.0) {
        return this.mediumColor;
      } else {
        return this.lowColor;
      }
    },
    handleMore() {
      this.$refs.vulnerability.open()
    },
    initialize() {
      const vulnId = this.$route.params.vulnId
      this.vulnId = vulnId
      getVulnerabilityDetail({vulnId}).then((res) => {
        this.vulnerability = res.data.data
        this.vectorString = this.vulnerability.vectorString
        this.vulnerableData=extractVulnerable(this.vulnerability.configurationsData)

      })
    },
    handleChangeTabs(val) {
      this.tabActive = val
    },
  },
}
</script>

<style lang="scss" scoped>
.title {
  margin-top: 2px;
}
.dropdown {
  margin-left: 10px;
}

::v-deep .ant-card-head {
  padding: 0 !important;
}
::v-deep .ant-tabs-nav-wrap {
  padding: 0 24px;
}
.text-muted {
  display: block;
}
.table-avatar-info {
  display: flex;
  align-items: center;
}
.table-avatar-info .ant-avatar {
  margin-right: 8px;
}

// Using vuejs "Deep Selectors"
.table-avatar-info::v-deep .ant-avatar-string {
  font-size: 12px;
}
.btn-status::v-deep .anticon {
  line-height: 0;
}
.avatar-info {
  margin-left: 0;
}
::v-deep .ant-card-head-title {
  padding: 16px;
}
.desc-item {
  padding: 12px;
  border-radius: 6px;
  background-color: rgba(97,97,97,0.01);
  margin-bottom: 12px;
}

.chart-title {
  text-align: center;
  margin-top: 4px;
  font-size: 14px;
  font-weight: 400;
}
::v-deep .vue-easy-pie-chart {
  position: relative;
  text-align: center;
  .inner-text {
    width: 100%;
    position: absolute;
  }
}
::v-deep .ant-descriptions-item-label {
  font-size: 14px;
}
.overview-item {
  width: 100%;
  display: flex;
  span:first-child {
    width: 68px;
  }
  span:last-child {
    flex: 1;
    color: #1a1a1a;
  }
}
.border-bottom {
  font-weight: 400;
  line-height: 40px;
  border-bottom: 1px solid #efefef;
}


 .categories-list::v-deep .anticon {
   font-size: 14px;
 }

.categories-list::v-deep .anticon svg {
  vertical-align: 1px;
}

</style>
