<!--雷达图-->
<template>
	<div>
		<!-- Radar chart -->
		<canvas ref="chart" class="chart-radar" :style="{'height': height + 'px'}"></canvas>
		<!-- / Radar chart -->
	</div>
</template>

<script>
	import { Chart, registerables } from 'chart.js';
	Chart.register(...registerables);

	export default ({
		props: ['vectorString','cvssVersion'],
		data(){
			return {
        ctx: null,
        radarData: [],
        labels: [],
				height: 500,
        cvssv2Labels: {
          av: '攻击向量',
          c: '机密性影响',
          ac: '攻击难度',
          i: '完整性影响',
          au: '身份验证',
          a: '可用性影响',
        },
        cvssv2Options: {
          av: [
            {text: '本地攻击', value: 'L', num: 1},
            {text: '相邻攻击', value: 'A', num: 2},
            {text: '网络攻击', value: 'N', num: 3}
          ],
          ac: [
            {text: '高', value: 'H', num: 3},
            {text: '中', value: 'M', num: 2},
            {text: '低', value: 'L', num: 1}
          ],
          au: [
            {text: '多重验证', value: 'M', num: 3},
            {text: '单一验证', value: 'S', num: 2},
            {text: '无需验证', value: 'N', num: 1}
          ],
          c: [
            {text: '无影响', value: 'N', num: 1},
            {text: '部分影响', value: 'P', num: 2},
            {text: '全部影响', value: 'C', num: 3}
          ],
          i: [
            {text: '无影响', value: 'N', num: 1},
            {text: '部分影响', value: 'P', num: 2},
            {text: '全部影响', value: 'C', num: 3}
          ],
          a: [
            {text: '无影响', value: 'N', num: 1},
            {text: '部分影响', value: 'P', num: 2},
            {text: '全部影响', value: 'C', num: 3}
          ]
        },
        cvssv3Labels:{
          av: '攻击向量',
          c: '机密性影响',
          ac: '攻击难度',
          i: '完整性影响',
          s: '影响范围',
          a: '可用性影响',
          pr: '特权要求',
          ui:'用户交互'
        },
        cvssv3Options:{
          av: [
            {text: '本地攻击', value: 'L', num: 1},
            {text: '相邻攻击', value: 'A', num: 2},
            {text: '网络攻击', value: 'N', num: 3}
          ],
          ac: [
            {text: '高', value: 'H', num: 3},
            {text: '低', value: 'L', num: 2},
            {text: '无', value: 'N', num: 1}
          ],
          pr: [
            {text: '高', value: 'H', num: 3},
            {text: '低', value: 'L', num: 2},
            {text: '无', value: 'N', num: 1}
          ],
          ui: [
            {text: '必须', value: 'R', num: 1},
            {text: '无', value: 'N', num: 3}
          ],
          c: [
            {text: '无影响', value: 'N', num: 1},
            {text: '低', value: 'L', num: 2},
            {text: '高', value: 'H', num: 3}
          ],
          s: [
            {text: '未确认', value: 'U', num: 2},
            {text: '已确认', value: 'C', num: 3}
          ],
          i: [
            {text: '无影响', value: 'N', num: 1},
            {text: '低', value: 'L', num: 2},
            {text: '高', value: 'H', num: 3}
          ],
          a: [
            {text: '无影响', value: 'N', num: 1},
            {text: '低', value: 'L', num: 2},
            {text: '高', value: 'H', num: 3}
          ]
        }
			} ;
		},
    watch: {

    },
		mounted () {
    		this.ctx = this.$refs.chart.getContext("2d");
        this.vectorStringMethod(this.vectorString)

		},
		// Right before the component is destroyed,
		// also destroy the chart.
		beforeDestroy: function () {
			this.chart.destroy() ;
      this.ctx = null
		},
    methods: {
      vectorStringMethod(val) {
        const vectorArr = val.split('/')  //CVSS:3.1/AV:N/AC:L/PR:L/UI:N/S:C/C:H/I:H/A:H
        if(this.cvssVersion==='3.1'){
          vectorArr.shift()
          vectorArr.forEach(el => {
            const arr = el.split(':')
            const labelKey = arr[0].toLowerCase()
            const dataObj = this.cvssv3Options[labelKey].find(item => item.value === arr[1]) || {}
            this.labels.push(this.cvssv3Labels[labelKey])
            this.radarData.push(dataObj.num)

            console.log(this.radarData)
          })
        }else {
          vectorArr.forEach(el => {
            const arr = el.split(':')
            const labelKey = arr[0].toLowerCase()
            const dataObj = this.cvssv2Options[labelKey].find(item => item.value === arr[1]) || {}
            this.labels.push(this.cvssv2Labels[labelKey])
            this.radarData.push(dataObj.num)
          })
        }


        this.chart = new Chart(this.ctx, {
          type: "radar",
          data: {
            labels: this.labels,
            datasets: [{
              data: this.radarData, // 这里是你自定义的各个维度的数据
              backgroundColor: 'rgba(80,113,145,0.1)', // 数据集的背景颜色
              borderColor: 'rgb(16,39,158)', // 数据集的边框颜色
              borderWidth: 1 // 数据集的边框宽度
            }]
          },
          options: {
            plugins: {
              legend: {
                display: false
              },
              label: {
                show: true,
                color: 'red'
              },
              tooltip: {
                intersect: false,
                callbacks: {
                  label: (context) => {
                    const labelStr = this.tooltipStr(context)
                    return labelStr
                  },
                },
                padding: 12,
              }
            },
            scales: {
              r: {
                max: 3,
                min: 0,
                ticks: {
                  stepSize: 1,
                  display: false,
                },pointLabels: {
                  fontSize: 16  // 设置坐标标签字体大小
                },
              },
            }
          }
        });
      },
      tooltipStr(row) {
        const labelKey = this.radarLabels(row.label)
        const labelObj = this.cvssv2Options[labelKey].find(el => el.num === +row.formattedValue) || {}
        return labelObj.text
      },
      radarLabels(label) {
        const labelArr = Object.entries(this.cvssv2Labels).find(el => el[1] === label) || []
        return labelArr[0]
      }
    }
	})

</script>

<style lang="scss" scoped>
</style>
